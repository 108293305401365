import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => {
  return (
      <div className='app__social'>
        <div>
        <a href='https://www.instagram.com/squareseed.photography/' target='_blank' ><BsInstagram/></a>
          </div>
          <div>
            <a href='https://www.facebook.com/kristian.besedes/' target='_blank' ><FaFacebookF/></a>
          </div>
    </div>
  )
}

export default SocialMedia
